body {
    background-color: white;
    color: black; 
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px 0px;
    overflow-x: hidden;
    max-width: 100%;
}

.n-content {
    max-width: 64em;
    margin:auto;
}

.n-banner {
    display: flex;
    
    margin-bottom: 10px;
    margin-top: 5px;
    
    color: #fafafa; 
    background-color: #900090;
    border-left: 6px solid #C000C0;
}

.n-banner.n-special {
    background-color: #C000C0;
    border-left: 6px solid #25865E;
}

.n-header {
    margin: 4px;
    padding-left: 15px;
    padding-right: 15px;
}

.n-banner img {
    max-height: 50px;
}

.n-content-container {
    display: block;
    padding-left: 2%;
    padding-right: 2%;
}
.n-content-container p {
    word-wrap: break-word;
}

.n-card-container img {
    display: block;
    width: 500px;
    max-width: 100%;
    border-radius: 6px;
}

.n-card-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 2%;
    padding-right: 2%;
}

.n-card-container .n-card {
    margin: 5px;
    word-wrap: break-word;
    width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 6px;
    max-width: 100%;
}

.n-card .n-card-content {
    padding-left: 10px;
    padding-right: 10px;
}

.n-card p {
    padding-left: 10px;
    padding-right: 10px;
}
