body {
  color: #000;
  max-width: 100%;
  background-color: #fff;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.n-content {
  max-width: 64em;
  margin: auto;
}

.n-banner {
  color: #fafafa;
  background-color: #900090;
  border-left: 6px solid #c000c0;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
}

.n-banner.n-special {
  background-color: #c000c0;
  border-left: 6px solid #25865e;
}

.n-header {
  margin: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.n-banner img {
  max-height: 50px;
}

.n-content-container {
  padding-left: 2%;
  padding-right: 2%;
  display: block;
}

.n-content-container p {
  word-wrap: break-word;
}

.n-card-container img {
  width: 500px;
  max-width: 100%;
  border-radius: 6px;
  display: block;
}

.n-card-container {
  flex-wrap: wrap;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
}

.n-card-container .n-card {
  word-wrap: break-word;
  width: 500px;
  max-width: 100%;
  border-radius: 6px;
  margin: 5px;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

.n-card .n-card-content, .n-card p {
  padding-left: 10px;
  padding-right: 10px;
}

/*# sourceMappingURL=index.b5fc46da.css.map */
